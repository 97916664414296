import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const Menu = () => {
  return (
    <Layout>
      <SEO title="Meni Konoba Fakin" lang="hr" />
      <div className="content menu-page">
        <div className="naslov-container">
          <h1>Meni</h1>
          <div>
            <Link to="/menu-en">EN</Link>
            <Link to="/menu-it">IT</Link>
            <Link to="/menu-de">DE</Link>
            <Link to="/menu-fr">FR</Link>
            <Link to="/menu-rus">RUS</Link>
          </div>
        </div>
        <h3>Hladnja predjela</h3>
        <ul>
          <li>Krema od tartufa sa sirom od tartufa</li>
          <li>Salata s kozicama, kozjim sirom i svježim krastavcima</li>
          <li>Tartar od junećeg filea s aiolijem i prepečencem</li>
          <li>Vitello tonnato</li>
          <li>Jetrena pašteta s tartufima i istarskom kobasicom</li>
          <li>Domaći pršut s maslinama</li>
          <li>
            Pijat Fakin za 2 osobe (domaći pršut, domaće kobasice, domaći sir i
            sir s tartufima)
          </li>
        </ul>
        <h3>Juhe</h3>
        <ul>
          <li>Juha od crnog tartufa</li>
          <li>Krem juha od šparoga</li>
          <li>Istarska maneštra</li>
        </ul>
        <h3>Topla predjela</h3>
        <ul>
          <li>Fuži s crnim tartufima</li>
          <li>Fritaja s crnim tartufima</li>
          <li>Špageti s kozicama, dagnjama i cherry rajčicama</li>
          <li>Paccheri s umakom bolognese i svježim hrenom</li>
          <li>Rimski njok u umaku od tartufa sa čipsom od istarske pancete</li>
          <li>Juneći gulaš s domaćim njokima</li>
        </ul>
        <h3>Glavna jela</h3>
        <ul>
          <li>
            File brancina gratiniran krustom od začinskog bilja, ratatouille i
            pire krumpir s vlascem
          </li>
          <li>
            Pileći file nadjeven sirom zapečen u pećnici, na podlozi od špinata
            i šampinjona
          </li>
          <li>
            Janjeći kotlet u umaku od timijana, pečeni krumpir s ružmarinom i
            julienne povrće
          </li>
          <li>Biftek s crnim tartufima na podlozi od pire krumpira</li>
          <li>
            Biftek u umaku od terana s pireom od celera i salatna garnitura
          </li>
          <li>Domaći ombolo s crnim tartufom i pekarskim krumpirom</li>
          <li>Povrće na žaru</li>
          <li>Ragu od divljači sa zapečenom palentom</li>
        </ul>
        <h3>Deserti</h3>
        <ul>
          <li>Čokoladni souffle</li>
          <li>Čokoladna krema od tartufa</li>
          <li>Čokoladne praline s tartufima</li>
          <li>Palačinke u umaku od naranče i cointreaua</li>
          <li>Crème brulee</li>
          <li>Pladanj istarskih sireva s maslinovim uljem, medom i bademima</li>
        </ul>
        <h3>Salate</h3>
        <ul>
          <li>Salata s tartufima</li>
          <li>Dnevni izbor sezonskih salata</li>
        </ul>
      </div>
    </Layout>
  )
}

export default Menu
